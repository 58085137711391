$primary: #020941;
$white: white;
$private: #af0000;

// $blue-primary: #4b8bfa;
$blue-primary: #2699fb;
// $blue-secondary: #4bc2fa;
$blue-secondary: #84c6ff;
$blue-light: #c4e1fa;
$blue-dark: #4b54fa;
$blue-ink: #010941;

$mark-primary: #4b8bfa;
$mark-secondary: #7b4bfa;

$red: #f71515;

$green-primary: #4be3fa;
$green-secondary: #48f0db;

$grey-primary: #707070;
$grey-light: #ccc;

$text-color: #333;
$text-shadow-color: $grey-light;

$button-color: $white;
$button-background-color: $primary;
$button-border-color: #8db8dd;
$button-shadow-color: $grey-light;

$navigation-panel-background-color: $primary;
$navigation-panel-text-color: #eee;
$navigation-panel-icon-hover-color: #1ea7fd;
$navigation-panel-icon-color: $blue-primary;

$text-primary: white;
