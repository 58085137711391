@import '../../../styles/styles.scss';

.DemoHeader {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  padding: 26px 0px;

  @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
    padding: 10px 0px 30px;
  }

  &-Divider {
    border: none;
    background-color: grey;
    width: 30%;
  }

  &-Amounts {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 128px;
    margin: auto;

    @media (max-width: $screen-xs-width), (max-height: $screen-xs-heigh) {
      height: 90px;
    }
  }

  &-Header {
    color: $blue-secondary;
  }

  &-Balance {
    position: relative;
    left: 30px;
  }

  &-Progress {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    top: 5px;
    left: 8px;
  }
}
